import React, { useState } from "react";
import { Input, Checkbox, Button, Form, message, Card, CheckboxChangeEvent } from "antd";
import axios from "axios";
import { AddAdminLevel } from "@src/core/endpoints/level";
import { BASE_URL } from "@src/utils/constants";

interface AdminLevelPermissions {
  levelName: string;
  canResetAdminPassword: boolean;
  canManageTeamAdmins: boolean;
  canManageAdmins: boolean;
  canManageTransactions: boolean;
  canUpdateTransaction: boolean;
  canManageAffilliates: boolean;
  canManageUsers: boolean;
  canManageTickets: boolean;
}

interface CreateAdminLevelProps {
  onLevelCreated: (levelId: string) => void;
}

const CreateAdminLevel: React.FC<CreateAdminLevelProps> = ({ onLevelCreated }) => {
  const [permissions, setPermissions] = useState<AdminLevelPermissions>({
    levelName: "Admin",
    canResetAdminPassword: true,
    canManageTeamAdmins: true,
    canManageAdmins: true,
    canManageTransactions: true,
    canUpdateTransaction: true,
    canManageAffilliates: true,
    canManageUsers: true,
    canManageTickets: true,
  });

  const handleLevelNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPermissions((prev) => ({ ...prev, levelName: value }));
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent,
    key: keyof AdminLevelPermissions
  ) => {
    setPermissions((prev) => ({ ...prev, [key]: e.target.checked }));
  };

  // Fetch all admin levels
  const fetchAdminLevel = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found.");
      }

      const response = await axios.get(`${BASE_URL}/admin_level`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data.data.levels; // Return levels array
      } else {
        throw new Error("Failed to fetch admin level data.");
      }
    } catch (error) {
      message.error("Error occurred while fetching admin levels.");
      throw error;
    }
  };

  // Submit the admin level
  const handleSubmit = async () => {
    try {
      // Create admin level
      const createResponse = await AddAdminLevel(permissions);
      if (createResponse.statusCode === 200) {
        message.success("Admin level created successfully!");

        // Fetch all admin levels
        const adminLevels = await fetchAdminLevel();
        // Find the `levelId` for the newly created level
        const createdLevel = adminLevels.find(
          (level: { levelName: string }) => level.levelName === permissions.levelName
        );

        if (createdLevel && createdLevel._id) {
          onLevelCreated(createdLevel._id); // Pass the levelId to the parent
        } else {
          message.error("Failed to find the created admin level.");
        }
      } else {
        throw new Error(createResponse.message || "Failed to create admin level.");
      }
    } catch (error) {
      console.error("Error creating admin level:", error);
      message.error("Failed to create admin level.");
    }
  };
  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <Form onFinish={handleSubmit} layout="vertical">
        <h2 className="font-bold text-2xl mb-4">Create Admin Level</h2>

        <Form.Item label="Level Name" name="levelName" rules={[{ required: true }]} initialValue={permissions.levelName}>
          <Input value={permissions.levelName} onChange={handleLevelNameChange} />
        </Form.Item>

        <Card title="Admin Management" style={{ marginBottom: "20px" }}>
          <Checkbox
            checked={permissions.canResetAdminPassword}
            onChange={(e) => handleCheckboxChange(e, "canResetAdminPassword")}
          >
            Can Reset Admin Password
          </Checkbox>
          <br />
          <Checkbox
            checked={permissions.canManageAdmins}
            onChange={(e) => handleCheckboxChange(e, "canManageAdmins")}
          >
            Can Manage Admins
          </Checkbox>
          <br />
          <Checkbox
            checked={permissions.canManageTeamAdmins}
            onChange={(e) => handleCheckboxChange(e, "canManageTeamAdmins")}
          >
            Can Manage Team Admins
          </Checkbox>
        </Card>

        <Card title="Transaction Management" style={{ marginBottom: "20px" }}>
          <Checkbox
            checked={permissions.canManageTransactions}
            onChange={(e) => handleCheckboxChange(e, "canManageTransactions")}
          >
            Can Manage Transactions
          </Checkbox>
          <br />
          <Checkbox
            checked={permissions.canUpdateTransaction}
            onChange={(e) => handleCheckboxChange(e, "canUpdateTransaction")}
          >
            Can Update Transactions
          </Checkbox>
        </Card>

        <Card title="User & Affiliate Management">
          <Checkbox
            checked={permissions.canManageAffilliates}
            onChange={(e) => handleCheckboxChange(e, "canManageAffilliates")}
          >
            Can Manage Affiliates
          </Checkbox>
          <br />
          <Checkbox
            checked={permissions.canManageUsers}
            onChange={(e) => handleCheckboxChange(e, "canManageUsers")}
          >
            Can Manage Users
          </Checkbox>
          <br />
          <Checkbox
            checked={permissions.canManageTickets}
            onChange={(e) => handleCheckboxChange(e, "canManageTickets")}
          >
            Can Manage Tickets
          </Checkbox>
        </Card>

        <Button type="primary" htmlType="submit" style={{ marginTop: "20px" }}>
          Create Admin Level
        </Button>
      </Form>
    </div>
  );
};

export default CreateAdminLevel;
