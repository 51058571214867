// pages/index.ts
import React from "react";

export const Dashboard = React.lazy(() => import("./dashboard"));
export const Users = React.lazy(() => import("./users"));
export const AllUsers = React.lazy(() => import("./all_users"));
export const AllAdmins = React.lazy(() => import("./admins"));
export const AllAdminLevels = React.lazy(() => import("./levels"));
export const Affiliates = React.lazy(() => import("./affiliates"));
export const Corporates = React.lazy(() => import("./coporates"));
export const Schools = React.lazy(() => import("./schools"));
export const Payout = React.lazy(() => import("./payout"));
export const Ticket = React.lazy(() => import("./tickets"));

