import React, { useState } from 'react';
import { Form, Input, Select, Button } from 'antd';
import back from "@assets/essay/Frame 1.png"
import { useNavigate } from 'react-router-dom';

const RaiseTicketForm = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values: any) => {
        setLoading(true);
        // Handle form submission here
        // ...
        setLoading(false);
    };

    return (
        <div className='w-full h-screen pr-[36px] pl-[36px] pt-[32px]' >
            <div className='flex flex-row items-center gap-x-2 text-sm font-normal leading-5 text-left mb-8'>

            <img src={back} alt="back "  style={{cursor:"pointer"}} onClick={()=> navigate(-1)} />
            Back
            </div>
     
            <Form form={form} onFinish={onFinish} layout="vertical" className='w-[50%] gap-y-2'>

                <Form.Item name="title" label="Title">
                    <Input placeholder="Enter title" />
                </Form.Item>
                <Form.Item name="category" label="Category">
                    <Select placeholder="Select category"/>
                        
                </Form.Item>
                <Form.Item name="description" label="Description">
                    <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item>
                    <button type='submit' className='bg-customOrange hover:bg-customOrange h-[40px] w-[111px] rounded text-white ' >
                        Send
                    </button>
                </Form.Item>
            </Form>
            </div>
       
    );
};

export default RaiseTicketForm;