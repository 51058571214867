import { Dropdown, Input } from 'antd';
import { useState } from 'react';
import Copy from '@icons/copy_white.svg';
import share from '@icons/share2.svg';
import { copyData } from '@src/utils/app_functions';
import { SearchOutlined } from '@ant-design/icons';




interface Props {
    value?: string;
    setValue?: React.Dispatch<React.SetStateAction<string>>;
    onClick?: () => void;
    schoolAdminPageName?: string;
    showInput?: boolean;
    menu?: React.ReactElement;
    isAffiliate?: boolean;
    refcode?: string;
}

const AdminHeader = ({ value, setValue, schoolAdminPageName, onClick, showInput, menu, refcode, isAffiliate }: Props) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);

    const handleNotificationClick = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    async function handleShare() {
        setShowShareModal(true);
    }

    return (
        <div className="w-full h-16 flex items-center justify-between pr-[45px] pl-[45px] bg-[#00027d]">
            <div className="text-xl font-medium leading-7 text-left text-white">{schoolAdminPageName}</div>
            {/* {showInput && !isAffiliate && (
                // <Input
                //     value={value}
                //     onChange={(e) => setValue && setValue(e.target.value)}
                //     placeholder="Search"
                //     className=" w-full md:w-[250px] h-[42px] border-2 rounded-lg"
                //     suffix={<SearchOutlined />}
                    
                // />
            )} */}
            {/* {
                isAffiliate && (
                    <div className='flex gap-x-3 items-center justify-between'>
                        <p className='font-normal text-[1rem] text-white'>Referral Code</p>

                        <div className=' flex gap-x-3 border border-[#C3C4CD] rounded-sm p-[0.75rem] text-white text-[0.875rem]'>
                            {refcode ?? ''}

                            <img onClick={() => copyData(`${refcode ?? ''}`)} src={Copy} alt="copy" />
                        </div>

                        <div className='flex items-center bg-transparent border border-[#C3C4CD] rounded-sm p-[0.75rem]'>
                            <img onClick={() => handleShare()} src={share} alt="copy" />
                        </div>
                    </div>
                )   
            } */}

          
{/* 
            <div className="relative" onClick={onClick} style={{ cursor: 'pointer' }}>
                <div className="absolute h-[12px] w-[12px] bg-customOrange rounded-full right-0 top-0 "></div>

                <Dropdown
                    menu={menu ? { items: [{ key: '1', label: menu }] } : undefined}
                    onOpenChange={handleNotificationClick}
                    trigger={['click']}
                >
                    <div className="relative" style={{ cursor: 'pointer' }}>
                        <div className="absolute h-[12px] w-[12px] bg-[#FEA450] rounded-full right-0 top-0" />
                        {renderNotificationIcon()}
                    </div>
                </Dropdown>
            </div> */}
        </div>
    );
};
export default AdminHeader;



const renderNotificationIcon = () => {
    return (
        <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7431 2.80153C18.0086 1.31509 15.7604 0.414062 13.312 0.414062C7.84039 0.414062 3.409 4.84545 3.409 10.3171V13.0886C3.409 13.4063 3.3398 13.8634 3.20656 14.3466C3.07314 14.8304 2.89913 15.2548 2.7377 15.5223L2.73584 15.5253L1.05977 18.3232L1.05824 18.3258C0.38801 19.4514 0.249318 20.7152 0.663635 21.8367C1.07812 22.9587 2.00519 23.8272 3.24658 24.241L3.24724 24.2412C4.82364 24.7656 6.43105 25.1635 8.05451 25.435C8.32987 26.2676 8.79809 27.0146 9.39825 27.6148C10.3871 28.6036 11.7744 29.2343 13.287 29.2343C15.7174 29.2343 17.7926 27.6401 18.519 25.4456C20.1641 25.1739 21.793 24.7725 23.39 24.2412L23.3907 24.241C25.8518 23.4207 26.9045 20.5409 25.5778 18.3237L25.5775 18.3232L23.9014 15.5253L23.8996 15.5223C23.739 15.2561 23.5648 14.8294 23.431 14.3424C23.3177 13.9307 23.251 13.5399 23.2331 13.2413C22.4115 12.9882 21.6565 12.5829 21.0012 12.0583V13.0886C21.0012 13.6623 21.117 14.3271 21.2836 14.9328C21.4497 15.5371 21.6908 16.1718 21.9915 16.6708L21.9928 16.6729L23.6667 19.4672L23.667 19.4677C24.2666 20.4704 23.7887 21.7604 22.6871 22.128C16.6069 24.1505 10.0304 24.1505 3.95018 22.128C3.29153 21.9083 2.91226 21.4968 2.75271 21.0649C2.59303 20.6327 2.61259 20.069 2.97119 19.4662L2.97179 19.4651L4.64449 16.6729L4.64556 16.6711C4.94554 16.1735 5.18687 15.5429 5.3535 14.9386C5.5205 14.333 5.63607 13.6682 5.63607 13.0886V10.3171C5.63607 6.07543 9.07036 2.64113 13.312 2.64113C15.4146 2.64113 17.3301 3.5024 18.7233 4.88932C18.9424 4.1301 19.2908 3.4257 19.7431 2.80153ZM10.973 26.04C10.8867 25.9537 10.8051 25.8624 10.7286 25.7667C12.431 25.9053 14.142 25.907 15.8447 25.7719C15.2412 26.523 14.3163 27.0072 13.287 27.0072C12.3977 27.0072 11.5679 26.6349 10.973 26.04ZM14.3984 7.3877C14.3984 6.77271 13.8999 6.27416 13.2849 6.27416C12.6699 6.27416 12.1714 6.77271 12.1714 7.3877V11.7825C12.1714 12.3974 12.6699 12.896 13.2849 12.896C13.8999 12.896 14.3984 12.3974 14.3984 11.7825V7.3877Z"
                fill="white"
            />
        </svg>
    );
};
