import React, {useState } from "react";
import CreateAdminLevel from "./components/createAdminLevel";
import CreateAdminAccount from "./components/createAdminAccount";
import { useNavigate } from "react-router-dom";

const AdminManagement: React.FC = () => {
    const [levelId, setLevelId] = useState<string | null>(null);
    const navigate = useNavigate();


    const handleAdminLevelCreated = (levelId: string) => {
        // setLevelId(levelId); // Store the levelId when admin level is created
        navigate('/home/all-permissions');
    };

    return (
        <div>
            {!levelId ? (
                <CreateAdminLevel onLevelCreated={handleAdminLevelCreated} />
            ) : (
                <CreateAdminAccount levelId={levelId} />
            )}
        </div>
    );
};

export default AdminManagement;
