/* eslint-disable @typescript-eslint/no-unused-vars */

import Header from "../components/header";
import { useNavigate } from "react-router";
import ExampleCard from "@src/components/example_card";
import { ToastContainer } from "react-toastify";

import Login from "./components/Login";

export default function LoginPage() {
    const navigatePage = useNavigate();
    
    return (
        <div className="w-full flex flex-col h-screen overflow-y-scroll bg-white gap-y-5 py-5 px-5 md:px-20">
            {/* <Helmet>
              <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
              <meta name="description" content="Best AI for educators, save time planning, creating teaching materials and get support for teaching practice" />
              <title>Login</title>
              <link rel="canonical" href="https://gmind.ai/login" />
              <meta name="keywords" content="gmind, ai, chat, gmind, gmindai" />
            </Helmet> */}
            <div className="flex flex-col md:flex-row items-center justify-between">
              <Login 
                handleForgetPasssword={() => navigatePage("/auth/forgot_password")}
                handleCreateAccount={() => navigatePage("/auth/signup")}
              /> 
              <ExampleCard />
            </div>
            <ToastContainer />
        </div>
    )
}
