import React, { useEffect, useState } from 'react';
import "./styles/index.scss";
import OtpInput from 'react-otp-input';
import Foot from '@icons/Footer.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '@src/components/Loading';

import { toast } from 'react-toastify';
import useEndpoint from '../auth/auth_endpoints';
import { GetDashboardData, GetYearlyData } from '@src/core/endpoints/user';
import { useUserContext } from '@src/context/UseProvider';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const OtpScreen = () => {
  const [otp, setOtp] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const query = useQuery();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const verifyWithOtp = async () => {
    if (!otp) {
      toast.error('Please enter OTP');
      return;
    }

    if (otp.length !== 6) {
      toast.error('Please enter a valid OTP');
      return;
    }

    try {
      setLoading(true);
      const response = await useEndpoint().verifyEmail({ code: otp, email });
      console.log("OTP Response:", response);
      setLoading(false);

      if (response.statusCode === 1) {
        const { token, details, levelDetails } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify({
          id: details._id,
          fullname: details.fullname,
          email: details.email,
          profileImg: details.profileImg,
          status: details.status,
          level: details.level,
          levelDetails: levelDetails,
        }));
        // Check if the user has updated their password
        if (details.passwordUpdated) {
          // Await the dashboard data request
          window.location.pathname = "/home/dashboard";
        } else {
          // Redirect to profile update page if password is not updated
          window.location.href = "/home/profile?showPasswordModal=true";
        }


      } else {
        toast.error(response.message || 'Something went wrong');
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    const tokenExtracted = query.get('token') || '';
    const emailExtracted = query.get('email') || '';

    setEmail(emailExtracted);
    setToken(tokenExtracted);

    navigate({
      pathname: window.location.pathname,
      search: ''
    }, { replace: true });
  }, []);

  return (
    loading ? (
      <Loading />
    ) : (
      <div className='pt-[8%] h-full w-full flex flex-col gap-y-3 items-center justify-between'>
        <div className='mb-10 flex flex-col gap-y-3 items-center justify-between'>
          <h1 className='text-[1.3rem] font-[Inter] font-medium'>Please enter the One-Time Password to verify your account</h1>
          <OtpInput
            value={otp}
            onChange={(value) => setOtp(value)}
            numInputs={6}
            inputStyle="otp-input"
            renderInput={(props) => <input {...props} className="otp-input" />}
          />
          <button onClick={() => { verifyWithOtp() }} className={`mt-10 w-[60%] flex flex-row items-center justify-center rounded-[0.6rem] bg-orange-300 w-[10rem] text-white font-normal text-center text-[0.8rem] px-5 py-3`}>
            Proceed
          </button>
          <h1 className='mt-5 text-[1.2rem] font-[Inter] font-medium'>Didn't get your code? <span className='text-[var(--gmind-orange)]'>Resend</span></h1>

        </div>
        <img alt="foot" src={Foot} className="self-end w-full" />
      </div>
    )
  );
}

export default OtpScreen;
