import useEndpoint from '@src/acquisition/auth/auth_endpoints';
import { Modal, Form, Input } from 'antd';
import { useState } from 'react';

interface UpdatePasswordModalProps {
    isVisible: boolean;
    onCancel: () => void;
}

const UpdatePasswordModal = ({ isVisible, onCancel }: UpdatePasswordModalProps) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onUpdatePassword = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();

            
            const response = await useEndpoint().updatePassword({
                oldPassword: values.oldPassword,
                password: values.confirmPassword,
            });
            
            console.log('Password update values:',response);

            if (response.success) {
                onCancel(); // Close the modal
            } else {
                console.error(response.message || 'Failed to update password');
            }
        } catch (error) {
            console.error('Error updating password:', error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            title="Update Password"
            visible={isVisible}
            onCancel={onCancel}
            onOk={onUpdatePassword}
            confirmLoading={loading}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="oldPassword"
                    label="Old Password"
                    rules={[{ required: true, message: 'Please enter your old password' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="New Password"
                    rules={[{ required: true, message: 'Please enter your new password' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Confirm New Password"
                    dependencies={['newPassword']}
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your new password',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords do not match'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UpdatePasswordModal;
