import React, { Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

import {
  Dashboard,
  Users,
  AllUsers,
  Affiliates,
  Corporates,
  Schools,
  Payout,
  Ticket,
  AllAdmins,
  AllAdminLevels,
} from "./pages";
import AppLayout from "./layouts/app_Layout";
import ErrorBoundary from "./ErrorBoundary";
import Loading from "./components/Loading";
import TicketPage from "./pages/tickets/components/TicketPage";
import RaiseTicketForm from "./pages/tickets/components/RaiseTicket";
import OtpScreen from "./acquisition/verify_mail/otp";
import NotFoundPage from "./pages/404";
import Login from "./acquisition/login";
import ProfileScreen from "./pages/profile";
import AdminManagement from "./pages/generate_admin";

const AppRouter = createBrowserRouter([
  {
    path: "home",
    element: (
      <Suspense fallback={<Loading />}>
        <AppLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "permission",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
            <AdminManagement />,
            </ErrorBoundary>
          </Suspense>
        ),       
      },
      {
        path: "all-permissions",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
            <AllAdminLevels />,
            </ErrorBoundary>
          </Suspense>
        ),       
      },
      {
        path: "users",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Users />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "all-users",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <AllUsers />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "admins",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <AllAdmins />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "affiliates",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Affiliates />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "corporates",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Corporates />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "schools",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Schools />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "payout",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Payout />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "ticket",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Ticket />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <ProfileScreen />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "ticket-details/:id",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <TicketPage />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: "raise-ticket",
        element: (
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <RaiseTicketForm />
            </ErrorBoundary>
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/auth" replace />,
  },
  {
    path: "/auth",
    element: <Login />,
    errorElement: <NotFoundPage />,
   
  },
  {
    path: "/verify-otp",
    element: <OtpScreen/>,
    errorElement: <NotFoundPage />,
   
  },
]);

export default AppRouter;
