import { useNavigate } from 'react-router';

interface props {
    onclick?: () => void;
    className?: string;
}

const BackButton = ({ onclick, className }: props) => {
    let navigate = useNavigate();
    return (
        <div
            className={`back-button-container flex flex-row items-center gap-x-3 ${className ?? ''}`}
            style={{
                fontFamily: "'Inter', sans-serif",
                fontSize: '14px',
                fontWeight: '400',
                color: 'black',
                cursor: 'pointer',
            }}
            onClick={() => (onclick != undefined ? onclick() : navigate(-1))}
        >
            <svg
  width="28"
  height="28"
  viewBox="0 0 28 28"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  className="dark:stroke-white"
>
  {/* Background circle with dynamic color based on dark mode */}
  <rect
    width="28"
    height="28"
    rx="14"
    fill="#4B465C"
    fillOpacity="0.08"
    className="dark:fill-white dark:fill-opacity-10"
  />
  {/* Arrow paths */}
  <path
    d="M16.5 9L11.5 14L16.5 19"
    stroke="#4B465C"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="dark:stroke-white"
  />
  <path
    d="M16.5 9L11.5 14L16.5 19"
    stroke="white"
    strokeOpacity="0.15"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="dark:stroke-white dark:stroke-opacity-50"
  />
  <path
    d="M16.5 9L11.5 14L16.5 19"
    stroke="black"
    strokeOpacity="0.2"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="dark:stroke-white dark:stroke-opacity-50"
  />
  <path
    d="M16.5 9L11.5 14L16.5 19"
    stroke="black"
    strokeOpacity="0.2"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="dark:stroke-white dark:stroke-opacity-50"
  />
  <path
    d="M16.5 9L11.5 14L16.5 19"
    stroke="black"
    strokeOpacity="0.2"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="dark:stroke-white dark:stroke-opacity-50"
  />
</svg>

            <span className="back-text dark:text-white">Back</span>
        </div>
    );
};

export default BackButton;
