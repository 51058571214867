/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Checkbox, Form } from 'antd';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useEndpoint from '../../auth/auth_endpoints';
import BackButton from '@components/BackButton';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

interface LoginProps {
    handleForgetPasssword: () => void;
    handleCreateAccount: () => void;
}

interface parmsQuery {
    fromPricing: string;
    amount: string;
    currency: string;
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Login = ({ handleForgetPasssword, handleCreateAccount }: LoginProps) => {
    const query = useQuery();
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const [queryObject, setQueryObject] = useState<parmsQuery | null>(null);
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    useEffect(() => {
        const fromPricing = query.get('fromPricing') || '';
        const amount = query.get('amount') || '';
        const currency = query.get('currency') || '';

        if (fromPricing && amount && currency) {
            const params: parmsQuery = { fromPricing, amount, currency };
            setQueryObject(params);

            // Fetch content or perform some action with the query parameters
            console.log('Fetching content with:', params);

            // Clear the URL parameters
            navigate(
                {
                    pathname: window.location.pathname,
                    search: '',
                },
                { replace: true },
            );
        }
    }, [query, navigate]);

    // const onFinish = (values: any) => {
    //     // navigate('/home');
    //     const email = values.email;
    //     const password = values.password;
    //     const rememberMe = values.rememberMe;

    //     // check if all data is passed
    //     if (!email || !password) {
    //         toast.error('Please enter email and password');
    //         return;
    //     }

    //     function handleCallback() {
    //         const callback = params.get('callback');
    //         if (queryObject) {
    //             window.location.href = `/dashboard/pricing?amount=${queryObject.amount}&currency=${queryObject.currency}`;
    //         } else {
    //             if (callback) {
    //                 window.location.replace(callback);
    //             } else {
    //                 const userData = localStorage.getItem('user');
    //                 if (userData) {
    //                     const userDetails = JSON.parse(userData);

    //                     // if ( userDetails?.plan?.details !== undefined ){
    //                     //     window.location.href = '/dashboard/chat';
    //                     // }else {
    //                     //     window.location.href = '/dashboard/pricing';
    //                     // }
    //                     // window.location.href = '/home/dashboard';
    //                 }
    //             }
    //         }
    //     }

    //     setLoading(true);
    //     useEndpoint()
    //         .login({ email, password, rememberMe })
    //         .then((res: any) => {
    //             setLoading(false);
    //             if (res?.success) {
    //                 localStorage.setItem('token', res?.data?.token);
    //                 const { id, fullname, email, isVerified, plan } = res.data;
    //                 localStorage.setItem('user', JSON.stringify({ id, fullname, email, isVerified, plan }));
    //                 // console.log("Navigating to:", `/verify-otp?email=${encodeURIComponent(email)}`);
    //                 window.location.href = `/verify-otp?email=${encodeURIComponent(email)}`;
    //                 // handleCallback();
    //                 toast.success(res?.message);
    //             } else {
    //                 toast.error(res?.message);
    //             }
    //         });
    // };

    const onFinish = (values: any) => {
        const email = values.email;
        const password = values.password;
        const rememberMe = values.rememberMe;
    
        if (!email || !password) {
            toast.error('Please enter email and password');
            return;
        }
    
        setLoading(true);
        useEndpoint()
            .login({ email, password, rememberMe })
            .then((res: any) => {
                setLoading(false);
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, fullname, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, fullname, email, isVerified, plan }));
    
                    // Test with window.location.href
                    console.log("Navigating to:", `/verify-otp?email=${encodeURIComponent(email)}`);
                    window.location.href = `/verify-otp?email=${encodeURIComponent(email)}&token=${encodeURIComponent(res?.data?.token)}`;    
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
                toast.error('Login failed');
            });
    };
    
    const handleCheckboxChange = () => {

        
        setRememberMe(!rememberMe);
    };

    return (
        <div className=" gap-y-3 w-full md:w-[40%]   md:px-10">
            <BackButton />

            <div className="login--title text-[1.5rem] mt-16 md:text-[2.5rem]">Sign In</div>

            <Form layout="vertical" onFinish={onFinish} initialValues={{ rememberMe }}>
                <Form.Item
                    name={'email'}
                    label={<label className="label"> Email</label>}
                    rules={[
                        {
                            message: 'email is required',
                            required: false,
                        },
                    ]}
                >
                    <input
                        className="px-3"
                        style={{
                            border: '1px solid #DBDADE',
                            borderRadius: '4px',
                            backgroundColor: 'transparent',
                            flex: 1,
                            width: '100%',
                            height: '40px',
                        }}
                        type="text"
                    />
                </Form.Item>
                <Form.Item
                    name={'password'}
                    label={<label className="label"> Password</label>}
                    rules={[
                        {
                            message: 'password is required',
                            required: false,
                        },
                    ]}
                >
                    <div className="flex items-center relative">
                        <input
                            className="px-3"
                            style={{
                                border: '1px solid #DBDADE',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                flex: 1,
                                width: '100%',
                                height: '40px',
                            }}
                            type={passwordVisible ? 'text' : 'password'}
                        />
                        <span
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            className="cursor-pointer absolute right-4"
                        >
                            {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </span>
                    </div>
                </Form.Item>
                <Form.Item>
                    <div className="check--box">
                        <div>
                            <Checkbox checked={rememberMe} onChange={handleCheckboxChange}>
                                Remember me
                            </Checkbox>
                        </div>
                        {/* <div
                            style={{
                                fontSize: '0.8125rem',
                                fontWeight: '400',
                                color: '#FF6600',
                                cursor: 'pointer',
                            }}
                            onClick={handleForgetPasssword}
                        >
                            Forgot Password?
                        </div>
                    </div> */}
                        </div>

                </Form.Item>
                <button type="submit" className="text-white bg-customOrange px-6 py-2 rounded create-account">
                    Login
                </button>

                {/* <GoogleSignInComponents handleSignIn={handleSignIn} />

                <div
                    style={{
                        fontSize: '13px',
                        fontWeight: '400',
                        fontFamily: "'Inter', sans-serif",
                        marginTop: '18px',
                        marginBottom: '100px',
                    }}
                    className="flex flex-row justify-center gap-1"
                >
                    New on GMind?{' '}
                    <span style={{ color: '#e55109', cursor: 'pointer' }} onClick={handleCreateAccount}>
                        Create an account
                    </span>
                </div> */}
            </Form>
        </div>
    );
};

export default Login;
