import React, { useState } from "react";
import { Input, Button, Form, message, Card, Typography } from "antd";
import axios from "axios";
import { BASE_URL } from "@src/utils/constants";

const { Title } = Typography;

interface AdminCreationFormProps {
  levelId: string; // The levelId passed from the previous form
}

const CreateAdminAccount: React.FC<AdminCreationFormProps> = ({ levelId }) => {
  const [adminData, setAdminData] = useState({
    fullname: "",
    email: "",
    level: levelId,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof adminData
  ) => {
    setAdminData({ ...adminData, [key]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/admin/add`, adminData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        message.success("Admin account created successfully!");
        setAdminData({ fullname: "", email: "", level: levelId }); // Reset form
      } else {
        message.error("Failed to create admin account.");
      }
    } catch (error: any) {
      console.error("Error creating admin account:", error);
      const errorMsg =
        error.response?.data?.message || "An unexpected error occurred.";
      message.error(`Failed to create admin account: ${errorMsg}`);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
      <Card style={{ maxWidth: 500, width: "100%", padding: "20px", borderRadius: "10px" }}>
        <Title level={3} style={{ textAlign: "center", marginBottom: "20px" }}>
          Create Admin Account
        </Title>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Full Name"
            name="fullname"
            rules={[{ required: true, message: "Full Name is required" }]}
          >
            <Input
              value={adminData.fullname}
              onChange={(e) => handleChange(e, "fullname")}
              placeholder="Enter full name"
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Enter a valid email address" },
            ]}
          >
            <Input
              value={adminData.email}
              onChange={(e) => handleChange(e, "email")}
              placeholder="Enter email address"
            />
          </Form.Item>

          <Form.Item label="Admin Level" name="level" initialValue={levelId}>
            <Input
              value={levelId}
              readOnly
              style={{
                backgroundColor: "#f5f5f5",
                color: "#1890ff",
                fontWeight: "bold",
              }}
            />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Create Admin Account
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default CreateAdminAccount;
