/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { RouterProvider, useLocation, useNavigate } from 'react-router-dom';
import Acquisition from './acquisitionRouter';
import Dashboard from './AppLayout';


import { getUrlPath } from './utils/app_functions';
import Loading from './components/Loading';
import { UserProvider } from './context/UseProvider';

export default function App() {
    const [mounted, setPageMounting] = useState(false);
    const [page, setPage] = useState('');

    const getTokenFromUrl = () => {
        const urlObj = new URL(window.location.href);
        const extractedToken = urlObj.searchParams.get('auth_redirect');

        if (!extractedToken) return null;

        localStorage.setItem('token', extractedToken);

        urlObj.searchParams.delete('auth_redirect');

        window.history.replaceState({}, '', urlObj.pathname);

        return extractedToken;
    }

    useEffect(() => {
        // check if user token exist
        const tokenFromUrl = getTokenFromUrl();
        const user = tokenFromUrl ?? localStorage.getItem('token');
        const userData = localStorage.getItem('user');
        const pagePathDetails = getUrlPath();
        if (user && pagePathDetails.pagename != 'verify' && pagePathDetails.pagename != 'verify_mail') {
            // get url path
            if (
                pagePathDetails.folder !== 'dashboard' &&
                (pagePathDetails.pagename === 'login' || pagePathDetails.pagename === 'signup')
            ) {
                // if ( userDetails.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';   
                // }
                // window.location.href = '/home/dashboard';   
            }
            setTimeout(() => {
                setPage('dashboard');
            }, 1000);
        } else {
            const { pathname } = window.location;
            const split = pathname.split('/');


            if (split[1] && split[1] === "dashboard" && pagePathDetails.pagename !== 'editor') {
                const { pathname, origin, href } = window.location;
                localStorage.setItem("callback", href)
                window.location.href = `/login?callback=${href}`;
            }
            setPage('acquisition');
        }
        setPageMounting(true);
    }, []);

    console.log("page ", page);

    return mounted ? (
        <UserProvider> {/* Wrap the entire app inside UserProvider */}
            <RouterProvider router={Dashboard} />
        </UserProvider>
    ) : null;
}
