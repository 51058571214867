import React, { useEffect, useRef, useState } from "react";
import AdminHeader from "./components/header";
import Sidebar from "./components/sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./app.scss";
import { getUrlPath } from "@src/utils/app_functions";
import Header from "@src/acquisition/components/header";
import { Bounce, ToastContainer } from "react-toastify";

const AppLayout = () => {
  const [isAffiliate, setIsAffiliate] = React.useState(false);
  const [schoolAdminPageName, setSchoolAdminPageName] =
    React.useState("Dashboard");
  const [showInput, setShowInput] = React.useState(true);
  const [value, setValue] = React.useState("");
  const [menu, setMenu] = React.useState<React.ReactElement | undefined>(
    undefined
  );
  const [selected, setSelected] = React.useState<string>("");
  const [sidebarCollapse, setSidebarCollapse] = React.useState(false);

  const [scale, setScale] = React.useState(1);
  const path = getUrlPath();
  const location = useLocation();
  const [isRoot, setIsRoot] = useState<boolean>(location.pathname === "/");
  const [isFeatures, setIsFeatures] = useState<boolean>(
    location.pathname === "/features"
  );
  const faqRef = useRef<HTMLDivElement | null>(null);
  const featuresRef = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState(true);
  const [showFrame, setShowFrame] = useState<boolean>(false);
  const [editorView, setEditorView] = useState<boolean>(false);
  const [isHome, setIsHome] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLanded, setIsLanded] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

 
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUserDetails(parsedUser);
      } catch (error) {
        console.error('Failed to parse user details from local storage:', error);
      }
    }
  }, []);

  useEffect(() => {
    const page = getUrlPath().pagename;
    if (page == "") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const urlDetails = getUrlPath();
    if (urlDetails.pagename.toLowerCase() === "pricing") {
      setShowFrame(true);
    } else if (urlDetails.pagename.toLowerCase() === "editor") {
      setEditorView(true);
    } else {
      setShowFrame(false);
    }

    setIsRoot(location.pathname === "/");
    setIsFeatures(location.pathname === "/features");
  }, [window.location.pathname]);

  
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const navigate = useNavigate();

  return (
    <div className=" overflow-hidden  h-screen margin-auto flex">
      <aside className="h-screen ">
        {!isLanded && (
          <Sidebar
            selected={(v: string) => {
              localStorage.setItem("activeModule", v);
              setSelected(v);
            }}
            userDetails={userDetails}
            style={{
              width: sidebarCollapse ? "10vw" : "20vw",
            }}
            collapsed={(b: boolean) => {
              setSidebarCollapse(b);
            }}
            className="h-full"
          />
        )}
      </aside>

      <div className="flex flex-col flex-1">
        <header>
          {isLanded ? (
            <Header
              onClickFaQ={() => scrollToSection(faqRef)}
              onClickFeature={() =>
                isFeatures
                  ? scrollToSection(featuresRef)
                  : navigate("/features")
              }
              onClickPricing={() =>
                isLoggedIn
                  ? navigate("/dashboard/pricing")
                  : navigate("/pricing")
              }
              isLoggedIn={isLoggedIn ?? false}
              isPromo={false}
            />
          ) : (
            <AdminHeader
              menu={menu}
              schoolAdminPageName={schoolAdminPageName}
              showInput={showInput}
              value={value}
              setValue={setValue}
              isAffiliate={isAffiliate}
            />
          )}
        </header>

        <main className=" w-full overflow-auto h-screen bg-custom-opacity ">
          <Outlet
            context={{
              setSchoolAdminPageName,
              setShowInput,
              setValue,
              setMenu,
              setIsAffiliate,
              value,
              setIsLanded,
              userDetails
            }}
          />
        </main>
      </div>
    </div>
  );
};

export default AppLayout;
