import { GetUser } from '@src/core/endpoints/user';
import { AffiliateRecordInterface } from '@src/core/interfaces/user';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'sonner';

interface UserDetailsData {
    profilePic: string | null;
    firstName?: string;
    userId?: string;
    affiliateRecord?: AffiliateRecordInterface;

}

export interface PayoutData {
    key: string;
    updatedAt: string;
    transactionId: string;
    fullName: string;
    email: string;
    amountPaid: number;
    planName: string;
    status: string;
    repaymentType: string;
    currencyType: string;
    channelUsed: string;
    paymentRef: string;
    totalAmountPaid: number;
    discountGranted: number;
    affiliateCodeUsed: string;
    affiliateDiscountGranted: number;
    discount: string;
    user: string;
    _id: string;
    date?: string;
}

interface DashboardDataInterface {
    usersStats?:        UsersStats;
    transactionsStats?: TransactionsStats;
}

interface TransactionsStats {
    totalTransactions?:        number;
    totalSuccessTransactions?: number;
    totalPendingTransactions?: number;
    totalFailedTransactions?:  number;
}

interface UsersStats {
    noOfUsers?:      number;
    noOfSchools?:    number;
    noOfCorporate?:  number;
    totalAffiliate?: number;
}

interface YearlyStatsDataInterface {
    statusCode?: number;
    stats?:      Stat[];
}

 interface Stat {
    id?:    number;
    label?: string;
    count?: number;
}



interface UserContextProps {
    userDetails: UserDetailsData | null;
    refreshUserDetails: () => Promise<void>;
    dashboardData: DashboardDataInterface | null;
    yearlyData: YearlyStatsDataInterface | null;
    setYearlyData: React.Dispatch<React.SetStateAction<any>>;
    setDashboardData: React.Dispatch<React.SetStateAction<DashboardDataInterface | null>>;
    transactions: PayoutData[]
    setTransactions: React.Dispatch<React.SetStateAction<PayoutData[]>>
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

// Custom hook to use UserContext
// eslint-disable-next-line react-refresh/only-export-components
export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userDetails, setUserDetails] = useState<UserDetailsData | null>(null);
    const [transactions, setTransactions] = useState<PayoutData[]>([]);
    const [requestStatus, setRequestStatus] = useState<boolean>(true);
    const [dashboardData, setDashboardData] = useState<DashboardDataInterface | null>(null);
    const [yearlyData, setYearlyData] = useState<YearlyStatsDataInterface | null>(null);

    const refreshUserDetails = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;

        const details = await GetUser(token);
        if (details.statusCode == 0) {
            setRequestStatus(false);
            toast.error(details.message);
            return;
        }
        const detailsFetched: UserDetailsData = details.data;
        setUserDetails(detailsFetched);
    };

    useEffect(() => {
        if (!requestStatus) return;
        if (!userDetails) {
            refreshUserDetails();
        }
    }, [userDetails, requestStatus]);

    useEffect(() => {
        console.log('userDetails:', userDetails);
    }, [userDetails]);

    return <UserContext.Provider value={{ userDetails, transactions, dashboardData , yearlyData, setYearlyData, setDashboardData, refreshUserDetails, setTransactions }}>{children}</UserContext.Provider>;
};
