
interface TicketDetailsModalProps {

  ticketId: string;
  ticketCategory: string;
  ticketTitle:string;
  ticketDescription: string;

}

const TicketDetailsModal: React.FC<TicketDetailsModalProps> = ({

  ticketId,
  ticketCategory,
  ticketTitle,
  ticketDescription,

}) => {
  return (
    <div className="flex flex-col gap-y-2 mt-4 p-[24px] border border-[#E5E5E5] rounded-md">
      <div className="flex flex-row items-center text-[16px] font-medium text-customOrange justify-between">
        <span>{ticketId}</span>
        <span>{ticketCategory}</span>

      </div>
      <p className="text-[16px] font-medium text-black">{ticketTitle}</p>
      <p className="text-[16px] font-medium text-gray-700">{ticketDescription}</p>

    </div>
  );
};
export default TicketDetailsModal;
