import BackButton from '@src/components/BackButton';
import { useEffect, useState, useRef } from 'react';
import defaultAvatar from '@icons/avatar.png';
import cameraIcon from '@icons/camera.png';
import { Form, Input, Select } from 'antd';
import { toast } from 'react-toastify';
import { updateUserDetails } from '@src/core/endpoints/user';
import UpdatePasswordModal from './updatePasswordModal';
import { useLocation, useOutletContext } from 'react-router-dom';
import Checkbox from 'antd/es/checkbox/Checkbox';

const ProfileScreen = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const showPasswordModal = queryParams.get('showPasswordModal') === 'true';
    const [showPassModal, setShowPassModal] = useState(showPasswordModal);
    const { userDetails } = useOutletContext<any>();
    const [isMobile, setIsMobile] = useState(true);
    const [loading, setLoading] = useState(false);
    const [profilePic, setProfilePic] = useState<string>('');
    const [inputValues, setInputValues] = useState<{ [key: string]: string }>({
    });
    const [levelDetails, setLevelDetails] = useState<{ [key: string]: boolean }>({});
    const [form] = Form.useForm();

    const imageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        observer.disconnect();
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, []);

    const inputArray = [
        { name: 'fname', label: 'Full Name', disabled: false },
        { name: 'email', label: 'Email', disabled: true },
        {
            name: 'role',
            label: 'Role',
            disabled: true,
        },
        { name: 'level', label: 'Level', disabled: true }, // Display level as read-only
        // { name: 'levelName', label: 'Level Name', disabled: true }, // Display levelName as read-only
        // {
        //     name: 'privileges',
        //     label: 'Privileges',
        //     disabled: true,
        //     isList: true,
        // },
        { name: 'createdAt', label: 'Account Created On', disabled: true }, // Display account creation date
    ];

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const onValueChange = (value: string, key: string) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [key]: value,
        }));
    };

    const onUpdateDetails = async () => {
        try {
            setLoading(true);
            const dataToSend = {
                firstName: inputValues['fname'],
                lastName: inputValues['lname'],
                role: inputValues['role'],
                language: inputValues['lang'],
            };

            const updated = await updateUserDetails(dataToSend);

            if (updated.statusCode != 1) {
                toast.error(updated.message ?? 'Error updating details');
            } else {
                toast.success('Data updated successfully');
                // You can call a function to refresh the user details after update
            }
        } catch (error) {
            console.error(error);
            toast.error('Error updating details');
        } finally {
            setLoading(false);
        }
    };

    // Populate form fields with userDetails on load

    useEffect(() => {
        if (userDetails) {
            // Populate initial input values
            const initialValues = {
                fname: userDetails.fullname || '',
                email: userDetails.email || '',
                role: userDetails.levelDetails?.levelName || '',
                level: userDetails.level || '',
                createdAt: userDetails.levelDetails?.createdAt || '',
            };

            setInputValues(initialValues);
            form.setFieldsValue(initialValues);

            // Set levelDetails for checkboxes
            const details = { ...userDetails.levelDetails };
            delete details._id;
            delete details.levelName;
            delete details.createdAt;
            delete details.updatedAt;
            delete details.__v;

            setLevelDetails(details);
        }
    }, [userDetails]);

    const handleCheckboxChange = (key: string, checked: boolean) => {
        setLevelDetails((prevDetails) => ({
            ...prevDetails,
            [key]: checked,
        }));
    };

    return (
        <section className="w-full h-auto py-8 px-4 md:px-8 flex flex-col gap-y-5 pb-8">
            <BackButton />
            <div className="mx-5 md:mx-[6.25rem] flex-col gap-y-3">
                <div className="flex flex-col md:flex-row gap-x-[2rem] md:gap-x-[5rem] h-auto  md:h-[19rem]">
                    {/* Avatar Image */}
                    <div className="w-[13rem] flex flex-col items-center md:items-start h-full self-center md:self-start">
                        <p className="hidden md:flex text-[0.875rem] text-[var(--gmind-deep-grey)] font-semibold">Photo</p>
                        <div className="w-full relative h-[12.5rem]">
                            <img
                                className="w-full h-full rounded-[50%]"
                                src={profilePic ? profilePic : defaultAvatar}
                                alt="avatar image"
                                loading="lazy"
                            />
                            <label htmlFor="fileInput" className="absolute w-[2rem] h-[2rem] right-5 bottom-2 cursor-pointer">
                                <img className="w-full h-full" src={cameraIcon} alt="Camera Icon" />
                                <input
                                    id="fileInput"
                                    type="file"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                />
                            </label>
                        </div>
                    </div>

                    {/* User Form */}
                    <div className="h-auto w-full md:w-[32.25rem]">
                        <Form
                            form={form}
                            layout="vertical"
                            className="flex flex-col md:flex-row flex-wrap gap-y-2 gap-x-3 w-full"
                            onFinish={() => { }}
                        >
                            {inputArray.map((item: any, index) => (
                                <Form.Item
                                    key={index}
                                    className={`w-full ${item.name == 'email' || item.name == 'role' ? 'md:w-[48.5%]' : 'md:w-full'} mb-1`}
                                    name={item.name}
                                    label={<label>{item.label}</label>}
                                >
                                    {item.isList && item.options ? (
                                        <Select
                                            className="w-full rounded-[0.25rem]"
                                            onSelect={(e) => onValueChange(e, item.name)}
                                            options={item.options.map((option: any) => ({ value: option, label: option }))}
                                            value={inputValues[item.name]}
                                        />
                                    ) : (
                                        <Input
                                            disabled={item.disabled}
                                            className="w-full"
                                            value={inputValues[item.name]}
                                            onChange={(e) => onValueChange(e.target.value, item.name)}
                                        />
                                    )}
                                </Form.Item>
                            ))}
                        </Form>
                        {/* Privileges Section */}
                        <div className="mt-5">
                            <h3>Privileges</h3>
                            {Object.entries(levelDetails).map(([key, value]) => (
                                <div key={key}>
                                    <Checkbox
                                        disabled
                                        checked={value}
                                        onChange={(e) => handleCheckboxChange(key, e.target.checked)}
                                    >
                                        {key}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="ml-3 mt-5 w-full md:w-[49.25rem] flex flex-col md:flex-row items-start justify-start md:justify-between gap-y-4">
                    <button
                        onClick={() => setShowPassModal(true)}
                        key="ok"
                        // variant={'outline'}
                        className={`bg-[#A5A3AE30] text-[#333237] ${isMobile ? 'w-full' : ''} `}
                    >
                        Update Password
                    </button>
                    {/* <button onClick={onUpdateDetails} disabled={loading}>
                        Save Changes
                    </button> */}
                </div>
            </div>

            {/* modal for update Password */}
            <UpdatePasswordModal isVisible={showPassModal} onCancel={() => setShowPassModal(false)} />
        </section >
    );
};
export default ProfileScreen;
