import { BASE_URL } from '@src/utils/constants';
import axios from 'axios';

export const AddAdminLevel = async (dataSent) => {

    try {
        const response = await axios.post(
            `${BASE_URL}/admin_level/add`,
            JSON.stringify(dataSent),
            {
                timeout: 12000,
                headers: {
                    "Content-Type": "application/json",
                },
                validateStatus: (status) => status >= 200 && status <= 505,
            }
        );

        if (response.status != 200) {
            return {
                statusCode: response.data?.statusCode,
                message: response.data?.message,
                data: response.data?.data || null, // You can optionally return the response data if needed
            };
        }

        return { statusCode: 200, message: 'Success', data: response.data };
    } catch (error) {
        // Check if error is an AxiosError
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status || 0;
            const message = error.message || 'Unknown error occurred';

            return {
                statusCode: statusCode,
                message: message,
                data: error.response?.data || null, // You can optionally return the response data if needed
            };
        } else {
            console.log(error);
            return { statusCode: 0, message: 'An unexpected error occurred' };
        }
    }
};