import React from "react";
import { useNavigate } from "react-router-dom";
import TicketDetailsModal from "./TicketCard";

const TicketPage = () => {
    const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-y- w-full h-full pr-[36px] pl-[36px] pt-[32px]">
      <div className="flex flex-row items-center pr-[24px] pl-[24px] bg-white justify-between w-full h-[80px]">
        <div className="flex flex-row items-center gap-x-2 text-2xl font-medium leading-9 text-left">

          <svg
          style={{cursor:"pointer"}}
          onClick={()=>navigate(-1)}
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.859375"
              width="40"
              height="40"
              rx="20"
              fill="#4B465C"
              fill-opacity="0.08"
            />
            <path
              d="M24.4241 12.8516L17.2812 19.9944L24.4241 27.1373"
              stroke="#4B465C"
              stroke-width="2.14286"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24.4241 12.8516L17.2812 19.9944L24.4241 27.1373"
              stroke="white"
              stroke-opacity="0.15"
              stroke-width="2.14286"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24.4241 12.8516L17.2812 19.9944L24.4241 27.1373"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="2.14286"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24.4241 12.8516L17.2812 19.9944L24.4241 27.1373"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="2.14286"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24.4241 12.8516L17.2812 19.9944L24.4241 27.1373"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="2.14286"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          View Details
        </div>
        <button className="w-[175px] h-[44px] bg-customOrange rounded-md p-[10px] gap-[8px] flex flex-row items-center justify-center text-white font-Poppins text-base font-normal leading-6 text-left">
          Download CSV
        </button>

      </div>

      <TicketDetailsModal ticketId={"123456789"} ticketCategory={"Content Generator"} ticketTitle={""} ticketDescription={""}/>


    </div>
  );
};

export default TicketPage;
